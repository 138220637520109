import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn } from '@angular/forms';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';

import { AppFormGroup } from './form-group';

export class AppFormArray<F extends AbstractControl<V, V>, V> extends FormArray<any> {
  destroy$ = new Subject<void>();

  constructor(
    // !important `F` should be NON abstract class
    private controlFactory: new (value?: V, options?: { onlySelf?: boolean; emitEvent?: boolean }) => F,
    controls?: Array<{ [key in keyof V]: AbstractControl }>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls ?? [], validatorOrOpts, asyncValidator);
  }

  getControls() {
    return this.controls as F[];
  }

  override getRawValue() {
    return super.getRawValue() as V[];
  }

  addItem(data: F) {
    this.push(data);
  }

  addNew() {
    this.push(new this.controlFactory());
  }

  removeItem(data: F) {
    this.destroyForm(data);
    this.removeAt(this.getControls().findIndex(item => item === data));
  }

  override patchValue(value: V[], options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.clear(options);
    value.forEach(item => this.push(new this.controlFactory(item, options), options));
    super.patchValue(value, options);
  }

  validate() {
    if (this.invalid) {
      this.updateValueAndValidity();
      this.markAllAsTouched();
    }
    return this.valid;
  }

  destroy() {
    Object.values(this.controls).forEach(item => this.destroyForm(item));
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  moveUp(index: number) {
    if (index <= 0) return;
    moveItemInArray(this.getControls(), index, index - 1);
    this.updateValueAndValidity();
  }

  moveDown(index: number) {
    const controls = this.getControls();
    if (index >= controls.length - 1) return;
    moveItemInArray(controls, index, index + 1);
    this.updateValueAndValidity();
  }

  private destroyForm(data: AbstractControl) {
    if ((data instanceof AppFormGroup || data instanceof AppFormArray) && data.destroy && !data.destroy$.closed) {
      data.destroy();
    }
  }
}
