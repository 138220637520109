export function mapToConfiguration<
  T extends { Configuration: string },
  R = Omit<T, 'Configuration'> & { Configuration: any }
>(data: T): R {
  return { ...data, Configuration: JSON.parse(data.Configuration) } as R;
}

export function mapConfigurationToJson<
  T extends { Configuration: any },
  R = Omit<T, 'Configuration'> & { Configuration: string | null }
>(data: T): R {
  return { ...data, Configuration: (data.Configuration && JSON.stringify(data.Configuration)) || null } as R;
}
